@import url('https://fonts.googleapis.com/css2?family=Zen+Loop:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zen+Loop:ital@0;1&display=swap');

body {
  font-family: "Zen Loop", sans-serif;
}

.App {
  text-align: center;
}

.logo {
  height: 70vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
}

a span {
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  padding-left: 8pt;
}